// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ State
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import ResourceRenderer from './resource-renderer'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Grid */
const Grid = ({ mediaState, resources }) => {
  const { currentWidth = 'isDesktop' } = mediaState
  const columns = {
    isExtraLargeDesktop: 4,
    isLargeDesktop: 4,
    isDesktop: 4,
    isTablet: 4,
    isMobile: 2,
  }

  return (
    <div
      className="grid standard without-animation"
      style={{ gridTemplateColumns: `repeat(${columns[currentWidth]}, 1fr)` }}
    >
      {map(resources, (resource) => (
        <ResourceRenderer resource={resource} />
      ))}
    </div>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  })
  // (dispatch) => ({
  //   updateLightOrDarkMode(payload) {
  //     dispatch(updateLightOrDarkModeFx(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedGrid = compose(
  withState // Add state
)(Grid)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedGrid
