// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import map from 'lodash/map'

import min from 'lodash/min'
import max from 'lodash/max'
import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ State
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** ResourceRenderer */
const ResourceRenderer = ({ lightOrDarkMode, resource }) => {
  const { title, variants = [], cover, darkCover, lightCover, slug } = resource
  const numberOfVariants = variants.length
  const variantText = numberOfVariants === 1 ? 'variant' : 'variants'
  const prices = map(variants, 'retailPrice')
  const minPrice = min(prices)
  const maxPrice = max(prices)

  let thisCover = cover
  if (isUndefined(lightCover) === false && isUndefined(darkCover) === false) {
    if (lightOrDarkMode === 'light') {
      thisCover = lightCover
    }
    if (lightOrDarkMode === 'dark') {
      thisCover = darkCover
    }
  }

  return (
    <Link className="product" to={slug}>
      <div className="image">
        <GatsbyImage image={getImage(thisCover)} />
        <p className="info">
          {numberOfVariants} {variantText}
          <br />₹{minPrice}–{maxPrice}
        </p>
      </div>
      <h3>
        <small>{title}</small>
      </h3>
    </Link>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    lightOrDarkMode: state.lightOrDarkMode,
  })
  // (dispatch) => ({
  //   updateLightOrDarkMode(payload) {
  //     dispatch(updateLightOrDarkModeFx(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedResourceRenderer = compose(
  withState // Add state
)(ResourceRenderer)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedResourceRenderer
