// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ State
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import './style.less'

import Grid from './grid'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import seoHelper from '../../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'LiGHT FiSH Store',
  nakedPageSlug: '/store',
  pageAbstract:
    'Focused on the minimalistic beauty of natural materials. Sustainable materials are carefully chosen and crafted into visionary and intelligently designed products. Much like the stages of growth of a plant, from seed to tree, LiGHT-FiSH chooses a design process that is organic and systematic.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** ProductsGrid */
const ProductsGrid = (props) => {
  const {
    pageContext: {
      edges: {
        data: {
          allResources: { edges: resourcesX },
        },
      },
    },
  } = props
  const resources = []
  map(resourcesX, (resource) => {
    resources.push(resource.node)
  })

  return (
    <StandardPageWrapper className="store-page" seoData={seoData} {...props}>
      <h1
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '1.86rem',
          textAlign: 'center',
        }}
      >
        LiGHT-FiSH Store
      </h1>
      <Grid resources={resources} />
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    storeLayoutState: state.storeLayoutState,
    lightOrDarkMode: state.lightOrDarkMode,
  })
  // (dispatch) => ({
  //   updateLightOrDarkMode(payload) {
  //     dispatch(updateLightOrDarkModeFx(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedProductsGrid = compose(
  withState // Add state
)(ProductsGrid)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedProductsGrid
